import Cervical from "../components/forms/Cervical";
import Cotovelo from "../components/forms/Cotovelo";
import DedosMao from "../components/forms/DedosMao";
import DedosPe from "../components/forms/DedosPe";
import EstruturaAbdominal from "../components/forms/EstruturaAbdominal";
import EstruturaCervical from "../components/forms/EstruturaCervical";
import EstruturaCranioFacial from "../components/forms/EstruturaCranioFacial"
import EstruturaPelvica from "../components/forms/EstruturaPelvica";
import EstruturaToracica from "../components/forms/EstruturaToracica";
import Fonacao from "../components/forms/Fonacao";
import Joelho from "../components/forms/Joelho";
import Lombar from "../components/forms/Lombar";
import Mao from "../components/forms/Mao";
import Neurologico from "../components/forms/Neurologico";
import Olho from "../components/forms/Olho";
import Ombro from "../components/forms/Ombro";
import Ouvido from "../components/forms/Ouvido";
import Pe from "../components/forms/Pe"
import Punho from "../components/forms/Punho";
import Quadril from "../components/forms/Quadril";
import Retroperitoneal from "../components/forms/Retroperitoneal";
import Toracico from "../components/forms/Toracico";
import Tornozelo from "../components/forms/Tornozelo";
import Cardiovascular from "../components/forms/Cardiovascular";

const bodyParts = [
    // Front view map exams
    {
        id: 'neurologic',
        label: 'Neurológico',
        component: Neurologico
    },
    {
        id: 'leftEyes',
        label: 'Olho Esquerdo',
        component: Olho
    },
    {
        id: 'rightEyes',
        label: 'Olho Direito',
        component: Olho
    },
    {
        id: 'craniofacialStructure',
        label: 'Estrutura Crânio Facial',
        component: EstruturaCranioFacial
    },
    {
        id: 'leftEar',
        label: 'Ouvido Esquerdo',
        component: Ouvido
    },
    {
        id: 'rightEar',
        label: 'Ouvido Direito',
        component: Ouvido
    },
    {
        id: 'phonation',
        label: 'Exame da Fonação',
        component: Fonacao
    },
    {
        id: 'cervicalStructure',
        label: 'Estrutura Cervical',
        component: EstruturaCervical
    },
    {
        id: 'leftShoulder',
        label: 'Ombro Esquerdo',
        component: Ombro
    },
    {
        id: 'rightShoulder',
        label: 'Ombro Direito',
        component: Ombro
    },
    {
        id: 'thoracicStructure',
        label: 'Estrutura Torácica',
        component: EstruturaToracica
    },
    {
        id: 'cardiovascular',
        label: 'Cardiovascular',
        component: Cardiovascular
    },
    {
        id: 'abdominalStructure',
        label: 'Estrutura Abdominal',
        component: EstruturaAbdominal
    },
    {
        id: 'leftHip',
        label: 'Quadril Esquerdo',
        component: Quadril
    },
    {
        id: 'rightHip',
        label: 'Quadril Direito',
        component: Quadril
    },
    {
        id: 'pelvicStructure',
        label: 'Estrutura Pélvica',
        component: EstruturaPelvica
    },
    {
        id: 'leftFist',
        label: 'Punho Esquerdo',
        component: Punho
    },
    {
        id: 'rightFist',
        label: 'Punho Direito',
        component: Punho
    },
    {
        id: 'leftHand',
        label: 'Mão Esquerda',
        component: Mao
    },
    {
        id: 'rightHand',
        label: 'Mão Direita',
        component: Mao
    },
    {
        id: 'leftHandFingers',
        label: 'Dedos da Mão Esquerda',
        component: DedosMao
    },
    {
        id: 'rightHandFingers',
        label: 'Dedos da Mão Direita',
        component: DedosMao
    },
    {
        id: 'leftKness',
        label: 'Joelho Esquerdo',
        component: Joelho
    },
    {
        id: 'rightKness',
        label: 'Joelho Direito',
        component: Joelho
    },
    {
        id: 'leftAnkle',
        label: 'Tornozelo Esquerdo',
        component: Tornozelo
    },
    {
        id: 'rightAnkle',
        label: 'Tornozelo Direito',
        component: Tornozelo
    },
    {
        id: 'leftFoot',
        label: 'Pé Esquerdo',
        component: Pe
    },
    {
        id: 'rightFoot',
        label: 'Pé Direito',
        component: Pe
    },
    {
        id: 'leftFootFingers',
        label: 'Dedos do Pé Esquerdo',
        component: DedosPe
    },
    {
        id: 'rightFootFingers',
        label: 'Dedos do Pé Direito',
        component: DedosPe
    },

    // Back view map exams
    {
        id: 'cervical',
        label: 'Segmento Cervical',
        component: Cervical
    },
    {
        id: 'thoracic',
        label: 'Segmento Torácico',
        component: Toracico
    },
    {
        id: 'leftElbow',
        label: 'Cotovelo Esquerdo',
        component: Cotovelo
    },
    {
        id: 'rightElbow',
        label: 'Cotovelo Direito',
        component: Cotovelo
    },
    {
        id: 'lumbar',
        label: 'Segmento Lombar',
        component: Lombar
    },
    {
        id: 'retroperitoneal',
        label: 'Estrutura Retroperitoneal',
        component: Retroperitoneal
    },
]

export {
    bodyParts
}